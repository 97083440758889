.App {
  background-color: #DFDEC6;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  font-size: 13px;
}

.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(12px + 5vw);
  color: #40334C;
  background-color: #B9B896;
  height: 25vh;
  width: 100vw;
}

.App-header hr {
  height: 10vw;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 3vw;
  margin-right: 3vw;
  border-color: #40334C;
}

.App-footer {
  color: #715649;
  margin-bottom: 3px;
}

.App-link {
  color: #61dafb;
}

.Content {
  background-color: transparent;
  height: 66vh;
  width: 70em;
  color: #715649;
  margin-top: 3vh;
  margin-bottom: 3vh;
}

/* TODO: Support smaller width, 320px min? */
/* Width at which to start basing content width by page size (instead of em) */
@media screen and (max-width: 1100px) {
  .Content {
    width: 85vw;
    height: auto;
  }
}

/* Width at which to collapse header */
@media screen and (max-width: 600px) {
  .App-header {
    flex-direction: column;
    font-size: calc(12px + 5vh);
  }
  .App-header hr {
    display: none;
  }
  .App-footer {
    margin-bottom: 21px;
  }
}