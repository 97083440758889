.Resume {
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: calc(12px);
  height: 66vh;
}

.Resume-column {
  flex-grow: 1;
}

.Resume > hr {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 3vw;
  margin-right: 3vw;
}

.Resume ul {
  text-align: left;
  margin-bottom: 30px;
}

.Resume li {
  margin-bottom: 12px;
}

.Resume h3 {
  color: #40334C;
}

@media screen and (max-width: 800px) {
  .Resume {
    flex-direction: column-reverse;
    height: auto;
  }
  .Resume > hr {
    margin-left: 0vh;
    margin-right: 0vh;
  }
}

@media screen and (min-width: 800px) {
  .Resume-column {
    overflow: scroll;
  }
}