.Contact {
  display: flex;
  text-align: center;
}

.Contact-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vh);
  color: #40334C;
}

.Contact ul {
  list-style-type: none;
  padding: 0;
}

.Contact ul li img {
  vertical-align: middle;
  padding-right: 5px;
  padding-left: 5px;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .Contact {
    align-items: center;
  }
}