body {
  margin: 0;
  padding: 0;
  font-family: Monaco, Menlo, Consolas, "Courier New", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Contact {
  display: -webkit-flex;
  display: flex;
  text-align: center;
}

.Contact-text {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vh);
  color: #40334C;
}

.Contact ul {
  list-style-type: none;
  padding: 0;
}

.Contact ul li img {
  vertical-align: middle;
  padding-right: 5px;
  padding-left: 5px;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .Contact {
    -webkit-align-items: center;
            align-items: center;
  }
}
.Resume {
  text-align: left;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(12px);
  height: 66vh;
}

.Resume-column {
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.Resume > hr {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 3vw;
  margin-right: 3vw;
}

.Resume ul {
  text-align: left;
  margin-bottom: 30px;
}

.Resume li {
  margin-bottom: 12px;
}

.Resume h3 {
  color: #40334C;
}

@media screen and (max-width: 800px) {
  .Resume {
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    height: auto;
  }
  .Resume > hr {
    margin-left: 0vh;
    margin-right: 0vh;
  }
}

@media screen and (min-width: 800px) {
  .Resume-column {
    overflow: scroll;
  }
}
.App {
  background-color: #DFDEC6;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  height: auto;
  font-size: 13px;
}

.App-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(12px + 5vw);
  color: #40334C;
  background-color: #B9B896;
  height: 25vh;
  width: 100vw;
}

.App-header hr {
  height: 10vw;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 3vw;
  margin-right: 3vw;
  border-color: #40334C;
}

.App-footer {
  color: #715649;
  margin-bottom: 3px;
}

.App-link {
  color: #61dafb;
}

.Content {
  background-color: transparent;
  height: 66vh;
  width: 70em;
  color: #715649;
  margin-top: 3vh;
  margin-bottom: 3vh;
}

/* TODO: Support smaller width, 320px min? */
/* Width at which to start basing content width by page size (instead of em) */
@media screen and (max-width: 1100px) {
  .Content {
    width: 85vw;
    height: auto;
  }
}

/* Width at which to collapse header */
@media screen and (max-width: 600px) {
  .App-header {
    -webkit-flex-direction: column;
            flex-direction: column;
    font-size: calc(12px + 5vh);
  }
  .App-header hr {
    display: none;
  }
  .App-footer {
    margin-bottom: 21px;
  }
}
